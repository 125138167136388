import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.showDialog,"max-width":"800","scrollable":""}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_vm._t("dialog-header",function(){return [(_vm.hasErrorItems)?_c('span',[_vm._v(_vm._s(_vm.$t("Some items couldn't be deleted")))]):(_vm.value)?_c('span',[_vm._v(_vm._s(_vm.value.message))]):_vm._e()]}),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.clearErrors}},[_c(VIcon,[_vm._v("close")])],1)],2),_c(VDivider),_c(VCardText,[_c(VAlert,{staticClass:"my-4",attrs:{"type":"error","dense":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('Are you sure? Force delete bypasses restrictions, which can break related items.')))])]),(_vm.hasErrorItems)?_c(VDataTable,{attrs:{"loading":_vm.loading,"headers":_vm.headers,"item-key":"id","items":_vm.failedItems,"show-select":"","items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData || {})]}}})],null,true),model:{value:(_vm.selectedToForceDelete),callback:function ($$v) {_vm.selectedToForceDelete=$$v},expression:"selectedToForceDelete"}}):_vm._e()],1),_c(VDivider),_c(VCardActions,{staticClass:"d-flex justify-space-between"},[_c(VBtn,{attrs:{"depressed":"","disabled":_vm.loading},on:{"click":_vm.clearErrors}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('button-action',{attrs:{"loading":_vm.loading,"disabled":!_vm.isDeleteDisabled && !!_vm.hasErrorItems,"depressed":"","label":_vm.$t('Force Delete'),"items":_vm.selectedToForceDelete,"color":"primary"},on:{"click":_vm.deleteSelectedItems}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }