import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"width":"800"},on:{"input":_vm.onChange}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Select image')))]),_c(VIcon,{on:{"click":function($event){return _vm.onChange(false)}}},[_vm._v("close")])],1),_c(VDivider,{staticClass:"mb-4"}),_c(VCardText,[_c('image-url-input',{attrs:{"value-key":"url","label":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c(VDivider),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"depressed":"","color":"primary","disabled":!_vm.selected.url},on:{"click":_vm.applied}},[_vm._v(" "+_vm._s(_vm.$t('Apply'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }