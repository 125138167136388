import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"max-width":"600px"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Upload or Insert PDF")]),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VFileInput,{attrs:{"label":"Choose PDF File","accept":"application/pdf","prepend-icon":"mdi-upload"},on:{"change":_vm.onFileChange},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c(VTextField,{attrs:{"label":"Or enter PDF URL","prepend-icon":"mdi-link"},model:{value:(_vm.pdfUrl),callback:function ($$v) {_vm.pdfUrl=$$v},expression:"pdfUrl"}})],1),(_vm.uploadProgress > 0)?_c(VProgressLinear,{staticClass:"my-2",attrs:{"value":_vm.uploadProgress}}):_vm._e(),(_vm.uploadMessage)?_c('p',[_vm._v(_vm._s(_vm.uploadMessage))]):_vm._e()],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onClose}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.formValid},on:{"click":_vm.apply}},[_vm._v("Apply")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }