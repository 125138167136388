<template>
  <v-dialog :value="value" v-model="visible" max-width="600px">
    <v-card>
      <v-card-title class="headline">Upload or Insert PDF</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="formValid">
          <v-file-input
            v-model="file"
            label="Choose PDF File"
            accept="application/pdf"
            @change="onFileChange"
            prepend-icon="mdi-upload"
          ></v-file-input>
          <v-text-field
            v-model="pdfUrl"
            label="Or enter PDF URL"
            prepend-icon="mdi-link"
          ></v-text-field>
        </v-form>
        <v-progress-linear
          v-if="uploadProgress > 0"
          :value="uploadProgress"
          class="my-2"
        ></v-progress-linear>
        <p v-if="uploadMessage">{{ uploadMessage }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="apply" :disabled="!formValid">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import axios from 'axios'

export default {
  name: 'QuillPdfDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  emits: ['apply', 'close', 'input'],
  setup(props, { emit }) {
    const visible = ref(props.value)
    const file = ref(null)
    const pdfUrl = ref('')
    const formValid = ref(false)
    const uploadProgress = ref(0)
    const uploadMessage = ref('')

    watch(() => props.value, (newValue) => {
      visible.value = newValue
    })

    const onFileChange = async (newFile) => {
      if (newFile) {
        await handleFileUpload(newFile)
      }
    }

    const handleFileUpload = async (file) => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('upload_preset', 'pdf_upload') // Replace with your upload preset
      formData.append('cloud_name', 'dgjpcpp4s') // Replace with your cloud name
      formData.append('resource_type', 'raw') // For non-image files like PDFs

      try {
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/dgjpcpp4s/raw/upload`, // Replace with your cloud name
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
              uploadProgress.value = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
            }
          }
        )

        uploadMessage.value = 'Upload successful!'
        pdfUrl.value = response.data.secure_url
        formValid.value = true
      } catch (error) {
        uploadMessage.value = 'Upload failed!'
        formValid.value = false
        console.error('Error uploading PDF:', error)
      }
    }

    const apply = () => {
      if (formValid.value) {
        const pdfName = file.value ? file.value.name : pdfUrl.value.split('/').pop();
        emit('apply', { url: pdfUrl.value, name: pdfName })
        emit('close')
        emit('input', false)
      }
    }

    const onClose = () => {
      emit('close')
      emit('input', false)
    }

    watch(pdfUrl, (newValue) => {
      formValid.value = !!newValue
    })

    return {
      visible,
      file,
      pdfUrl,
      formValid,
      uploadProgress,
      uploadMessage,
      onFileChange,
      apply,
      onClose
    }
  }
}
</script>

<style scoped>
</style>
