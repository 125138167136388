import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"width":"800"},on:{"input":_vm.onChange}},[_c(VCard,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.applied.apply(null, arguments)}}},[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Assign link'))+" "+_vm._s(_vm.value))]),_c(VIcon,{on:{"click":function($event){return _vm.onChange(false)}}},[_vm._v("close")])],1),_c(VDivider),_c(VCardText,[_c(VTextField,{staticClass:"my-4",attrs:{"outlined":"","label":_vm.$t('URL'),"error-messages":_vm.errors['url'],"hide-details":"auto"},on:{"input":_vm.onUrlChange},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1),_c(VDivider),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"depressed":"","color":"primary","disabled":!_vm.isValid,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Apply'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }